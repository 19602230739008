export const formatCurrency = (value: number, decimals = 2): string => {
    const formatted = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: decimals }).format(value);
    if (value && value < 0) {
        return `(${formatted})`;
    }
    return formatted;
};

/**
 * Mutates an array of objects by parsing the selected fields as Date objects
 * @param {Object[]} data - An array of objects
 * @param {string[]} dateFields - An array of field names to be parsed as Date objects
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseDates = <T>(data: T[], fields: (keyof T)[], behavior?: "TRIM Z"): T[] => {
    const parsed = data.map((row) => {
        const mutableRow = { ...row };
        fields.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = mutableRow[field] as any;
            if (value) {
                const preparedValue = behavior === "TRIM Z" ? value.slice(0, -1) : value;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mutableRow[field] = new Date(preparedValue) as any;
            }
        });
        return mutableRow;
    });
    return parsed as T[];
};

export const getIsoFormDate = (date: string | null): string => {
    if (!date) {
        return "";
    }
    return date.split("T")[0];
};

export const maskSSN = (SSN: string | null, maskCharacter?: string): string | null => {
    if (!SSN) {
        return null;
    }
    const lastFour = SSN.substring(SSN.length - 4);
    const localMaskChar = maskCharacter || "X";
    return `${[localMaskChar, localMaskChar, localMaskChar].join("")}-${[localMaskChar, localMaskChar].join("")}-${lastFour}`;
};

export const formatSSN = (value: string) => {
    if (!value) return value;
    const ssn = value.replace(/[^\d]/g, "");
    const ssnLength = ssn.length;
    if (ssnLength < 4) {
        return ssn;
    }
    if (ssnLength < 6) {
        return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
};

export const kendoDateFormat = "{0: MM/dd/yyyy}";
export const kendoCurrencyFormat = "{0:C}";

export const redact = (stringToRedact: string, charactersToKeep: number): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const redacted: any = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hyphens: any = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const numArray: any = [];
    stringToRedact.split("").forEach((number, index) => {
        if (/[-/,.()&'":;]/.test(number)) {
            hyphens.push({ symbol: number, index });
        } else {
            numArray.push(number);
        }
    });
    numArray.forEach((number: string, index: number) => {
        if (index >= numArray.length - charactersToKeep) {
            redacted.push(number);
        } else {
            number = "x";
            redacted.unshift(number);
        }
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hyphens.forEach((hyphen: any) => {
        redacted.splice(hyphen.index, 0, hyphen.symbol);
    });
    return redacted.join("");
};

export const notImplemented = () => {
    throw new Error(`Not implemented!`);
};

export const convertToCentralTime = (date: Date): Date => {
    // Convert the date to UTC
    const utcDate = new Date(date.toISOString());

    // Convert the UTC date to Central Time
    const options = { timeZone: "America/Chicago", hour12: false };
    const centralTimeString = utcDate.toLocaleString("en-US", options);
    const centralTimeDate = new Date(centralTimeString);

    return centralTimeDate;
};
