/* eslint-disable @typescript-eslint/no-var-requires */

const env = process.env.REACT_APP_ENVIRONMENT || "dev";
const rootConfig = require("./config.json");
const envConfig = require(`./config.${env}`);

const config: {
    auth0: {
        audience: string;
        clientId: string;
        domain: string;
    };
    authenticateApiUrl: string;
    databrokerApiUrl: string;
    emailApiUrl: string;
    noReplyEmail: string;
    fileApiUrl: string;
    supportEmailAddress: string;
    supportPhoneNumber: string;
    trustReportApiUrl: string;
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    innovestApiUrl: string;
    recaptchaKey: string;
    paymentApiUrl: string;
    trustApiBaseUrl: string;
} = { ...rootConfig, ...envConfig };

export default config;
