import React, { createContext, useEffect, useState } from "react";

import { DropdownItemProps } from "semantic-ui-react";
import { TrustRow } from "../types";
import { notImplemented } from "../services/Library";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import useTrustDataService from "../hooks/useTrustDataService";

interface AppContextProviderProps {
    children: React.ReactNode;
}

interface AppContextProps {
    states?: { ListItemID: number; ItemDescription: string }[] | undefined;
    trusts: TrustRow[] | undefined;
    setTrusts: (trusts: TrustRow[]) => void;
    setStateOptions: (data: DropdownItemProps[] | undefined) => void;
    stateOptions: DropdownItemProps[] | undefined;
}

export const AppContext = createContext<AppContextProps>({
    states: [],
    trusts: [],
    setTrusts: notImplemented,
    setStateOptions: notImplemented,
    stateOptions: []
});

const AppContextProvider: React.FC<AppContextProviderProps> = (props: AppContextProviderProps) => {
    const { user } = useAuth0();
    const { children } = props;
    const { getStates, getTrusts } = useTrustDataService();
    const [trusts, setTrusts] = useState<TrustRow[]>();
    const [stateOptions, setStateOptions] = useState<DropdownItemProps[] | undefined>();
    const [states, setStates] = useState<{ ListItemID: number; ItemDescription: string }[] | undefined>();

    useEffect(() => {
        if (stateOptions || !user) return;
        (async () => {
            try {
                const stateData = await getStates();
                setStates(stateData);
                setStateOptions(
                    stateData.map((s) => ({
                        key: s.ListItemID,
                        text: s.ItemDescription,
                        value: s.ListItemID
                    }))
                );
            } catch (error) {
                console.error(error);
                toast.error("Unable to get states list");
            }
        })();
    }, [getStates, setStateOptions, stateOptions, user]);

    useEffect(() => {
        if (!user || trusts) return;
        (async () => {
            const trustData = await getTrusts();
            setTrusts(trustData);
        })();
    }, [getTrusts, trusts, user]);

    return (
        <AppContext.Provider
            value={{
                states,
                trusts,
                setTrusts,
                stateOptions,
                setStateOptions
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
export default AppContextProvider;
