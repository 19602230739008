import React, { createContext, useEffect, useState } from "react";

import { InternalUser } from "../types";
import { notImplemented } from "../services/Library";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "../hooks/useSproc";
import useTrustDataService from "../hooks/useTrustDataService";

interface AuthContextProps {
    internalUser?: InternalUser;
    setUserRoles: (roles: { ContactType: string; PaymentEnabled: boolean }[]) => void;
    userRoles?: { ContactType: string }[];
}
export const AuthContext = createContext<AuthContextProps>({
    internalUser: undefined,
    setUserRoles: notImplemented,
    userRoles: []
});

interface AuthContextProviderProps {
    children: React.ReactNode;
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }: AuthContextProviderProps) => {
    const { isAuthenticated, user } = useAuth0();
    const [userRoles, setUserRoles] = useState<{ ContactType: string; PaymentEnabled: boolean }[]>();
    const [internalUser, setInternalUser] = useState<InternalUser>();
    const { isInternalUser } = useTrustDataService();
    const sproc = useSproc();

    useEffect(() => {
        (async () => {
            if (isAuthenticated && user) {
                try {
                    const roleList = await sproc("GetUserRoles");
                    setUserRoles(roleList);
                    const data = await isInternalUser();
                    setInternalUser(data);
                } catch (err) {
                    console.log(err);
                }
            }
        })();
    }, [isAuthenticated, user, isInternalUser, sproc]);

    return (
        <AuthContext.Provider
            value={{
                internalUser,
                setUserRoles,
                userRoles
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
