import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";

import PrivateRoute from "./components/PrivateRoute";
import SimplePlaceholder from "./components/SimplePlaceholder";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "./hooks/useSproc";
import useTrustDataService from "./hooks/useTrustDataService";

const Home = lazy(() => import("./features/home/Home"));
const Register = lazy(() => import("./features/register/Register"));
const Support = lazy(() => import("./features/support/Support"));
const TrustDetails = lazy(() => import("./features/trust/TrustContextProvider"));
const UpdateContacts = lazy(() => import("./features/update-contacts/EditContacts"));
const UserCommunicationPreferences = lazy(() => import("./components/UserCommunicationPreferences"));
const TPA = lazy(() => import("./features/tpa/TPA"));

const AppRoutes: React.FC = () => {
    const [isCommunication, setIsCommunication] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const sproc = useSproc();

    const { checkUserCommunicationPreferences } = useTrustDataService();
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await checkUserCommunicationPreferences();
            } catch (err) {
                if (err instanceof Error) {
                    setIsCommunication(true);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [checkUserCommunicationPreferences, sproc]);

    // if communication preferences need an update
    if (!loading && isCommunication && isAuthenticated) {
        return (
            <Suspense fallback={<SimplePlaceholder />}>
                <Switch>
                    <Route path="/register" exact component={Register} />
                    <Route path="/support" exact component={Support} />
                    <PrivateRoute path="/communication" exact component={UserCommunicationPreferences} />
                    <Redirect to="/communication" />
                </Switch>
            </Suspense>
        );
    }

    return (
        <Suspense fallback={<SimplePlaceholder />}>
            {!loading && (
                <Switch>
                    <Route path="/register" exact component={Register} />
                    <Route path="/support" exact component={Support} />
                    <PrivateRoute path="/" isCommunication={isCommunication} exact component={Home} />
                    <PrivateRoute path="/update-contacts" isCommunication={isCommunication} exact component={UpdateContacts} />
                    <PrivateRoute path="/trust/:id/:tab" exact component={TrustDetails} />
                    <PrivateRoute path="/communication" exact component={UserCommunicationPreferences} />
                    <PrivateRoute path="/tpa" exact component={TPA} />
                    <Redirect to="/" />
                </Switch>
            )}
        </Suspense>
    );
};

export default AppRoutes;
