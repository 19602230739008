import { AppState, Auth0Provider } from "@auth0/auth0-react";

import App from "./App";
import AppContextProvider from "./components/AppContextProvider";
import AuthContextProvider from "./components/AuthContextProviders";
import History from "./services/History";
import React from "react";
import ReactDOM from "react-dom";
import config from "./config";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

const onRedirectCallback = (appState?: AppState) => {
    History.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider {...config.auth0} redirectUri={window.location.origin} onRedirectCallback={onRedirectCallback}>
            <AuthContextProvider>
                <AppContextProvider>
                    <App />
                </AppContextProvider>
            </AuthContextProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

datadogRum.init({
    applicationId: "6ae763a3-adc4-4531-8f7b-8a6248c8765d",
    clientToken: "pub9965fe4a27593df6a8f321a6a44d3511",
    site: "datadoghq.com",
    service: "litco-portal",
    env: process.env.REACT_APP_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
    allowedTracingUrls: [/https:\/\/cloud.*\.itm21st\.com\/.*/]
});

datadogLogs.init({
    clientToken: "pub9965fe4a27593df6a8f321a6a44d3511",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
});
